import {Component, Input} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {FormatService} from 'src/app/services/format.service';
import {Destroyed} from '../../directives/destroyed.directive';
import {UserFamilyDTO} from '../../../../model/dto/user-family.dto';
import {CreateVisitFormComponent} from '../../../users/family-setting/visit-family-form/create-visit-form/create-visit-form.component';

@Component({
  selector: 'app-dialog-add-visit',
  templateUrl: './dialog-add-visit.component.html',
  standalone: true,
  imports: [CreateVisitFormComponent]
})
export class DialogAddVisitComponent extends Destroyed {
  @Input() family?: UserFamilyDTO[];

  constructor(
    public readonly dialogRef: MatDialogRef<DialogAddVisitComponent>,
    public readonly formatService: FormatService
  ) {
    super();
  }

  dismiss(): void {
    this.dialogRef.close();
  }
}
