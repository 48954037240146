import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Destroyed} from 'src/app/components/shared/directives/destroyed.directive';
import {UserFamilyDTO} from 'src/app/model/dto/user-family.dto';
import {VisitCreationDTO} from 'src/app/model/dto/visit-creation-infos.dto';
import {VisitFamilyPlaceEnum} from 'src/app/model/enums/visit-family-place.enum';
import {FormSelectItem} from 'src/app/model/form/select-item.model';
import {PMotif} from 'src/app/model/visit/p-motif.model';
import {CurrentUserService} from 'src/app/services/current-user.service';
import {FormatService} from 'src/app/services/format.service';
import {FamilyVisitWebService} from 'src/app/services/webservices/familyvisit.webservice';
import {ParamWebservice} from 'src/app/services/webservices/param.webservice';
import {UserWebservice} from 'src/app/services/webservices/user.webservice';
import {User} from '../../../../../model/user/user.model';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogActions} from '@angular/material/dialog';
import {SelectMultipleComponent} from '../../../../shared/form-parts/form-items/select-multiple/select-multiple.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {SelectComponent} from '../../../../shared/form-parts/form-items/select/select.component';
import {NgClass, NgFor, NgIf, UpperCasePipe} from '@angular/common';
import { getMobileToken } from 'src/app/utils/utils.static';

@Component({
  selector: 'app-create-visit-form',
  templateUrl: './create-visit-form.component.html',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    SelectComponent,
    MatDatepickerModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    NgFor,
    MatCheckboxModule,
    NgIf,
    SelectMultipleComponent,
    MatDialogActions,
    MatButtonModule,
    UpperCasePipe
  ]
})
export class CreateVisitFormComponent extends Destroyed implements OnInit {
  //#region Params & Ctor

  @Input() family?: UserFamilyDTO[];
  @Input() isInDialog: boolean = false;

  @Output() dismissEvent = new EventEmitter<void>();

  youngs: User[] = [];
  parents: User[] = [];

  visitFamilyFormGroup: UntypedFormGroup;

  places: FormSelectItem[] = [];
  visitors: FormSelectItem[] = [];
  reasons: FormSelectItem[] = [];

  maxDate: Date;
  endDate: Date;

  isCreateBtnLoading = false;

  isSaveError: boolean | null = null;

  constructor(
    private readonly formatService: FormatService,
    private readonly fb: UntypedFormBuilder,
    private readonly userWebService: UserWebservice,
    private readonly familyVisitWebService: FamilyVisitWebService,
    private readonly currentUserService: CurrentUserService,
    private readonly router: Router,
    private readonly paramWebService: ParamWebservice,
    private readonly route: ActivatedRoute
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.createFormGroup();
    await this.getParamDatas();
    if (!this.family) {
      await this.getFamily();
    }
    this.setFamilyInfos();
    this.completeFormGroup();
  }

  //#endregion

  //#region Events

  onBtnCreateVisitClick(): void {
    this.isCreateBtnLoading = true;
    this.isSaveError = null;
    const values = this.visitFamilyFormGroup.value;

    const newVisit: VisitCreationDTO = {
      idVisitor: values.visitor,
      idResponsable: values.parent,
      date: this.formatDateTime(values.date, values.hourStart, values.minuteStart),
      place: VisitFamilyPlaceEnum[values.place],
      motifs: []
    };
    const selectedYoungs = this.youngs.filter((y) => values[`youngSelection${y.id}`] === true);

    selectedYoungs.forEach((young) => {
      newVisit.motifs.push({
        idYoung: young.id,
        listOfIdMotif: values[`reason${young.id}`]
      });
    });

    this.familyVisitWebService
      .createVisitFamily(newVisit)
      .pipe(this.untilDestroyed())
      .subscribe({
        next: (visitId: number) => {
          if (this.isInDialog) {
            this.dismissEvent.emit();
          }
          const token = getMobileToken();
          if (token) {
            this.router.navigate(['/family-setting-mobile/visit-family', visitId]);
          } else {
            this.router.navigate(['/family-setting/visit-family', visitId]);
          }
        },
        error: () => {
          this.isSaveError = true;
        }
      })
      .add(() => {
        this.isCreateBtnLoading = false;
      });
  }

  onBtnCancelClick(): void {
    if (this.isInDialog) {
      this.dismissEvent.emit();
    }
  }

  //#endregion

  //#region Form

  isVisitFamilyFormValid(): boolean {
    if (!this.visitFamilyFormGroup) {
      return false;
    }
    const values = this.visitFamilyFormGroup.value;
    let isValid = this.visitFamilyFormGroup.valid;

    const selectedYoungs = this.youngs.filter((y) => values[`youngSelection${y.id}`] === true);

    // Verify that at least one young is selected
    if (selectedYoungs.length === 0) {
      isValid = false;
    }

    // Verify that at least one reason is selected
    if (
      !this.youngs.some(
        (y) => values[`youngSelection${y.id}`] === true && values[`reason${y.id}`].length > 0
      )
    ) {
      isValid = false;
    }
    return isValid;
  }

  isYoungSelected(id: number): boolean {
    return this.visitFamilyFormGroup.value[`youngSelection${id}`];
  }

  private createFormGroup(): void {
    this.visitFamilyFormGroup = this.fb.group({
      visitor: [null, Validators.required],
      place: [null, Validators.required],
      date: [null, Validators.required],
      hourStart: [null, Validators.required],
      minuteStart: [null, Validators.required],
      parent: [null, Validators.required]
    });
  }

  //#endregion

  //#region Get Datas

  private completeFormGroup(): void {
    if (this.parents.length === 1) {
      this.visitFamilyFormGroup.controls.parent.setValue(this.parents[0].id);
    }
    this.youngs.forEach((young) => {
      this.visitFamilyFormGroup.addControl(
        'youngSelection' + String(young.id),
        new UntypedFormControl(false)
      );
    });

    this.youngs.forEach((young) => {
      this.visitFamilyFormGroup.addControl('reason' + String(young.id), new UntypedFormControl([]));
    });
  }

  private async getFamily(): Promise<void> {
    return new Promise((resolve, reject) => {
      const userId = this.route.snapshot.paramMap.get('id');
      // Get Family
      this.userWebService
        .getFamilyForUser(parseInt(userId, 10))
        .pipe(this.untilDestroyed())
        .subscribe({
          next: (response: UserFamilyDTO[]) => {
            this.family = response;
            resolve();
          },
          error: (error) => {
            reject(error);
          }
        });
    });
  }

  private setFamilyInfos(): void {
    this.youngs = this.family.map((f) => f.young);
    const parentList = [];
    this.family.forEach((f) => {
      f.youngResponsable.forEach((yr) => {
        if (!parentList.some((p) => p.id === yr.relative.id)) {
          parentList.push(yr.relative);
        }
      });
    });
    this.parents = parentList;
  }

  private async getParamDatas(): Promise<void> {
    await Promise.all([this.getVisitPlaces(), this.getReasons(), this.getVisitors()]);
  }

  private async getVisitPlaces(): Promise<void> {
    const keys = Object.values(VisitFamilyPlaceEnum).filter(
      (p) => typeof VisitFamilyPlaceEnum[p as string | VisitFamilyPlaceEnum] === 'string'
    );
    this.places = keys.map((p) => {
      return {
        id: parseInt(p.toString(), 10),
        name: this.formatService.formatVisitFamilyPlace(parseInt(p.toString(), 10))
      };
    });
    this.places.unshift({id: null, name: null});
  }

  private async getReasons(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.paramWebService
        .getAllVisitReasons()
        .pipe(this.untilDestroyed())
        .subscribe({
          next: (response: PMotif[]) => {
            this.reasons = response.map((r) => {
              return {
                id: r.id,
                name: r.label
              };
            });
            resolve();
          },
          error: () => {
            reject();
          }
        });
    });
  }

  //#endregion

  //#region Tools

  private async getVisitors(): Promise<void> {
    return new Promise((resolve, reject) => {
      const currentUser = this.currentUserService.currentUser;
      this.userWebService
        .getAllVisitorsActif()
        .pipe(this.untilDestroyed())
        .subscribe({
          next: (data: {id: number; firstName: string; lastName: string}[]) => {
            this.visitors = data.map((v) => {
              return {
                id: v.id,
                name: `${v.firstName} ${v.lastName}`
              };
            });

            // If current user is RI => select him by default
            const currentUserIndex = this.visitors.findIndex((v) => v.id === currentUser.id);

            if (currentUserIndex !== -1) {
              const currentVisitor = this.visitors[currentUserIndex];
              this.visitors.splice(currentUserIndex, 1);
              this.visitors.unshift(currentVisitor);
              this.visitFamilyFormGroup.get('visitor').setValue(this.visitors[0]?.id);
            } else {
              this.visitors.unshift({id: null, name: null});
            }
            resolve();
          },
          error: () => {
            reject();
          }
        });
    });
  }

  private formatDateTime(date: Date, hour: number, minute: number): Date {
    date.setTime(date.getTime() + minute * 60 * 1000 + hour * 60 * 60 * 1000);
    return date;
  }

  //#endregion
}
